import React from "react";
import styled from "styled-components";

import { grey500 } from "ui/colors";

const ButtonContent = styled.span`
    display: block;
    height: inherit;
    pointer-events: none;
    background-color: ${(props) => props.baseColor || "none"};
    background-clip: border-box;

    color: ${(props) => (props.altText ? grey500 : "white")};
    border: 1px solid;
    border-bottom-width: 3px;
    border-color: ${(props) =>
        props.baseColor ? "rgba(0,0,0,.4)" : "transparent"};
    border-radius: inherit;

    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);

    transition:
        background 0.2s,
        top 0.2s,
        box-shadow 0.2s;
    white-space: nowrap;
    & > span {
        position: static;
        z-index: 1;
    }

    &:before {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "\xa0";
        border-radius: inherit;
        background-clip: border-box;
        background-color: ${(props) =>
            props.hover ? "rgba(0,0,0,0.2)" : "transparent"};
    }
`;
const ButtonRoot = styled.button`
    line-height: 1.5;
    text-align: center;

    position: relative;
    top: 0px;
    border-radius: 3px;

    transition:
        top 0.2s,
        box-shadow 0.2s;

    &:not([disabled]):active {
        top: 2px;
    }

    &[disabled] {
        opacity: 0.3;
        cursor: default;
    }
`;

// type Props = {
//     disabled: boolean,
//     type: "button" | "submit" | "reset",
//     children?: React.ReactNode,

//     onClick: (event: SyntheticMouseEvent) => void,

//     className?: string,

//     baseColor: string,
//     altText?: string,
//     dataCy?: string,
// };
// type State = {
//     hovering: boolean,
// };

class RawButton extends React.Component {
    static defaultProps = {
        disabled: false,
        type: "button",
        baseColor: false,
    };

    state = {
        hovering: false,
    };

    render() {
        return (
            <ButtonRoot
                className={this.props.className}
                baseColor={this.props.baseColor}
                type={this.props.type}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                onMouseOver={this._onMouseOver}
                onMouseOut={this._onMouseOut}
                onTouchEnd={this._onMouseOut}
                onFocus={this._onMouseOver}
                onBlur={this._onMouseOut}
                data-cy={this.props.dataCy}
            >
                <ButtonContent
                    hover={!this.props.disabled && this.state.hovering}
                    baseColor={this.props.baseColor}
                    altText={this.props.altText || !this.props.baseColor}
                >
                    <span>{this.props.children}</span>
                </ButtonContent>
            </ButtonRoot>
        );
    }

    _onMouseOver = () => {
        this.setState({
            hovering: true,
        });
    };

    _onMouseOut = () => {
        this.setState({
            hovering: false,
        });
    };
}
export default RawButton;
export { ButtonRoot, ButtonContent };
