import React from "react";
import styled from "styled-components";

import { grey500, grey300 } from "ui/colors";
import {
    ErrorMessage,
    Controls,
    ErrorA,
    ContactLink,
} from "errors/components/ErrorMessage";

import BrokenHouse from "icons/BrokenHouse";

const StyledBrokenHouse = styled(BrokenHouse)`
    height: 200px;
    margin: 0 auto;
    color: ${grey300};
    margin-bottom: 20px;
`;

const ErrorRoot = styled.div`
    max-width: 1200px;
    padding: 50px;
    margin: 20px auto;
    text-align: center;

    color: ${grey500};
`;

const AppError = () => (
    <ErrorRoot>
        <StyledBrokenHouse />
        <h3>Sorry — we&apos;re having a moment.</h3>
        <ErrorMessage>
            <p>Something went wrong with the page you were using.</p>
            <p>
                Try reloading the page. If you continue to have problems, please{" "}
                <ContactLink>contact support</ContactLink> and let us know what
                you were trying to do.
            </p>
            <Controls>
                <ErrorA href="/organisations">
                    <span>Take me home</span>
                </ErrorA>
                <ErrorA href="">
                    <span>Reload the page</span>
                </ErrorA>
            </Controls>
        </ErrorMessage>
    </ErrorRoot>
);

export default AppError;
