import React from "react";
import styled from "styled-components";
import defaultImageSrc from "auth/images/heroImage.jpg";

import { Link, Logo, Paragraph, SubHeading, Text } from "ui";
import {
    spacing100,
    spacing200,
    spacing250,
    spacing400,
    spacing50,
} from "ui/spacing";
import { portraitTablet, mobile } from "uiKit/theme/mediaQueries";

import { blue400, grey400 } from "ui/colors";

interface IProps {
    heroContent?: any;
    children: React.ReactNode;
}

export default function HeroLayout(props: IProps) {
    const heroContent = props.heroContent || {
        organisationName: "Dynamic Analysis Engineering Consulting",
        organisationLocation: "Montana, United States",
        summary: `Design of refinery gas skid - made with ClearCalcs.`,
        heroImageSrc: defaultImageSrc.src,
        ctaText: "Submit your photo!",
    };

    return (
        <Root>
            <HeroSection backgroundUrl={heroContent.heroImageSrc}>
                <Link href="/" inheritColor>
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                </Link>
                <HeroContainer>
                    <HeroCallout>
                        <SubHeading bold>
                            {heroContent.organisationName}
                        </SubHeading>
                        <Paragraph>
                            <Text bold>{heroContent.organisationLocation}</Text>
                        </Paragraph>
                        <Paragraph>{heroContent.summary}</Paragraph>
                        <Paragraph bold>
                            Want to see your project here?{" "}
                            <Link
                                href="https://blueprint.clearcalcs.com/t/do-you-have-project-photos-to-showcase-with-clearcalcs/288?utm_source=login&utm_medium=website&utm_campaign=submit-your-project&utm_term=submit-your-project&utm_content=submit-your-project"
                                inheritColor
                            >
                                <Text>{heroContent.ctaText}</Text>
                            </Link>
                        </Paragraph>
                    </HeroCallout>
                </HeroContainer>
            </HeroSection>
            <FormColumn>
                <MobileLogo>
                    <Link href="/" inheritColor>
                        <LogoWrapper noShadow>
                            <Logo />
                        </LogoWrapper>
                    </Link>
                </MobileLogo>
                <FormContainer>{props.children}</FormContainer>
                <FormFooter>
                    <Link href="/contact" inheritColor>
                        Contact
                    </Link>
                    <Link href="/privacy" inheritColor>
                        Privacy
                    </Link>
                    <Link href="/terms" inheritColor>
                        Terms of Use
                    </Link>
                    <Link href="https://clearcalcs.instatus.com/" inheritColor>
                        System Status
                    </Link>
                </FormFooter>
            </FormColumn>
        </Root>
    );
}

const Root = styled.div`
    display: flex;
    min-height: 100vh;

    > * {
        flex: 1;
    }
`;

const HeroSection = styled.div<{ backgroundUrl: string }>`
    display: flex;
    // We want to column flex here so we can push the
    // HeroCallout to the bottom left
    flex-direction: column;
    padding: ${spacing100};

    color: white;
    background-image: linear-gradient(
            rgba(0, 0, 0, 0.3),
            transparent 20%,
            transparent 60%,
            rgba(0, 0, 0, 0.9) 100%
        ),
        url(${(props) => props.backgroundUrl});
    background-position: center;
    background-size: cover;

    ${mobile`
        display: none;
    `}
`;

const LogoWrapper = styled.div<{ noShadow?: boolean }>`
    // Force the height here so the logo stays constrained
    height: 2em;

    // Logo
    > * {
        height: inherit;
        // CSS filter applies to all none transparent pixel
        // in the box, so we can use this to add a shadow
        // around the logo.
        filter: ${(props) =>
            props.noShadow
                ? "none"
                : "drop-shadow(0 0 4px rgba(0, 0, 0, 0.3))"};
    }
`;

const HeroContainer = styled.div`
    display: flex;
    flex: 1;
    // Push callout to the bottom
    align-items: flex-end;
`;
const HeroCallout = styled.div`
    max-width: 500px;
    padding: ${spacing100};

    text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`;

const FormColumn = styled.div`
    display: flex;
    // Column flex so we can for the footer to the
    // bottom of the colum.
    flex-direction: column;
    width: 30%;
    max-width: 540px;

    background-color: white;

    ${mobile`
        max-width: 100%;
    `}
`;
const FormContainer = styled.div`
    flex: 1;
    padding: ${spacing250} ${spacing400};

    ${portraitTablet`
        padding: ${spacing100} ${spacing200};
    `}
`;

const FormFooter = styled.nav`
    min-height: 1.5em;
    padding: ${spacing100};

    font-size: 0.875em;
    text-align: right;

    > * {
        margin: 0 ${spacing50};
    }
`;

const MobileLogo = styled.div`
    display: none;
    padding: ${spacing100};
    color: ${blue400};

    ${mobile`
        display: block;
    `}
`;
