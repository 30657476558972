import * as React from "react";
import styled from "styled-components";
import { grey300, grey400, blue400 } from "ui/colors";
import { Link } from "ui";
import UIButton from "uiKit/button/UIButton";

export const ErrorMessage = styled.div`
    margin: 20px auto;
    border: 1px solid ${grey300};
    padding: 20px;
    max-width: 600px;
    border-radius: 3px;
    text-align: left;

    p {
        margin-bottom: 0.5em;
    }
`;

export const Controls = styled.div`
    text-align: right;
    margin: 0 -5px;
    margin-top: 20px;

    > * {
        margin: 5px;
    }
`;

const errorControlStyle = `
    border: 1px solid ${grey400};
    border-bottom-width: 3px;
    border-radius: 3px;
    display: inline-block;
`;

export const ErrorLink = styled(Link)`
    padding: 0.75em;
    ${errorControlStyle};
    &:hover > span {
        border-bottom: 1px solid;
    }
`;

export const ErrorA = styled.a`
    padding: 0.75em;
    ${errorControlStyle};
    &:hover > span {
        border-bottom: 1px solid;
    }
`;

export const ErrorButton = styled(UIButton)`
    width: auto;
    ${errorControlStyle};
`;

const ContactLinkRoot = styled.a`
    color: ${blue400};

    &:hover > span {
        border-bottom: 1px solid;
    }
`;

export function ContactLink({ children }) {
    return (
        <ContactLinkRoot href="mailto:help@clearcalcs.com">
            <span>{children}</span>
        </ContactLinkRoot>
    );
}

export default ErrorMessage;
