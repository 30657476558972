import React from "react";

import HeroLayout from "auth/components/HeroLayout";
import { Heading, Link, Paragraph } from "ui";
import { grey400 } from "ui/colors";
import styled from "styled-components";

export default function AppLoading() {
    return (
        <HeroLayout>
            <noscript>
                <style>
                    {`
                    body {
                        margin: 0;
                        font-family: sans-serif;
                    }
                    *{
                        line-height: 1.5;
                    }
                    noscript + * {
    display: none;
}`}
                </style>
                <Heading>JavaScript disabled</Heading>
                <Paragraph>
                    ClearCalcs uses modern web features that requires JavaScript
                    to be enabled in your browser. Please enable JavaScript and
                    refresh the page to try again.
                </Paragraph>
                <Paragraph>
                    If you need help with this process{" "}
                    <a
                        href="https://www.enable-javascript.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>please click here.</span>
                    </a>
                </Paragraph>
            </noscript>
            <LoadingMessage>
                <Heading>Loading&hellip;</Heading>
            </LoadingMessage>
        </HeroLayout>
    );
}

const LoadingMessage = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    color: ${grey400};

    > * {
        flex: 1;
    }
`;
