import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { makeObservable } from "mobx";
import { observer } from "mobx-react";
import Head from "next/head";

import AppLoading from "framework/components/AppLoading";
import AppError from "errors/components/AppError";
import SessionStore from "framework/SessionStore";
import logUnhandledError from "framework/utils/logUnhandledError";
import { SessionStoreContext } from "framework/SessionStoreContext";

const AppRoot = styled.div`
    height: 100%;
`;

type Props = {
    sessionStore: SessionStore;
    loadingView?: React.ReactElement;
    forceMobileResponsive?: boolean;
    children: React.ReactNode;
};
type State = {
    errored?: boolean;
};

@observer
class App extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        makeObservable(this);
    }
    readonly state: State = {};

    render() {
        if (!this.props.sessionStore?.initialized) {
            return (
                <AppRoot>{this.props.loadingView || <AppLoading />}</AppRoot>
            );
        }

        if (this.state.errored) {
            return (
                <AppRoot>
                    <AppError />
                </AppRoot>
            );
        }

        return this.props.children;
    }

    componentDidCatch(error, info) {
        logUnhandledError(error, info);
        this.setState({ errored: true });
    }
}

function AppWithSessionStore(props) {
    const sessionStore = useContext(SessionStoreContext);
    const [viewportMetaContent, setviewportMetaContent] = useState(
        "width=device-width, initial-scale=1",
    );

    useEffect(() => {
        // TODO: The auth pages (/login, /forgot/password, etc.) are mobile responsive now, so we don't
        // need this to run on those pages. However, the UnauthenticatedRoute component still needs the
        // sessionStore to exist and be initialized to work, so short of refactoring the way the whole
        // app works, lets just suppress this for now.
        if (!props.forceMobileResponsive) {
            // https://stackoverflow.com/questions/15040408/achieving-min-width-with-viewport-meta-tag
            const MIN_WIDTH = 768;
            function updateViewport() {
                const screen = window.screen;

                if (screen.width < MIN_WIDTH) {
                    setviewportMetaContent(`width=${MIN_WIDTH}`);
                } else {
                    setviewportMetaContent(
                        "width=device-width, initial-scale=1",
                    );
                }
            }
            const mql = window.matchMedia(`(min-width: ${MIN_WIDTH}px)`);
            mql.addEventListener("change", updateViewport);
            updateViewport();

            return () => {
                mql.removeEventListener("change", updateViewport);
            };
        }
    }, [props.forceMobileResponsive]);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content={viewportMetaContent}
                    key="viewport"
                />
            </Head>
            <App {...props} sessionStore={sessionStore} />
        </>
    );
}
export default AppWithSessionStore;
