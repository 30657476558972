import React from "react";
import InlineIcon from "./components/InlineIcon";

type Props = {
    className?: string;
};

const BrokenHouse = ({ className }: Props) => (
    <InlineIcon className={className} viewBox="0 0 100 100">
        <path d="M53.798,17.364L50.771,25.1l3.868,2.018l-4.638,7.118l2.732,3.14l-3.813,12.457l2.187,2.451l-1.026,7.512h5.902v17.488  H44.381V59.795h4.138l0.066-7.735l-2.69-2.438l2.214-12.836l-2.971-2.803l3.488-7.399l-3.615-1.513l1.682-7.903L33.619,29.779  l-4.077,4.246L18.569,45.586l4.736-0.421l2.859,36.771l22.42-1.233l25.223,2.13l3.363-36.771l4.26,0.112L53.798,17.364z   M31.509,54.276l-0.779-6.681l6.682-0.778l0.778,6.68L31.509,54.276z M66.67,54.276l-6.681-0.779l0.778-6.68l6.681,0.778  L66.67,54.276z" />
    </InlineIcon>
);

export default BrokenHouse;
