import React from "react";
import styled from "styled-components";
const SVGRoot = styled.svg<{ block: boolean }>`
    height: ${(props) => (!props.block ? "1em" : "inherit")};
    overflow: visible;
`;

export type Props = {
    className?: string;
    children?: React.ReactNode;
    viewBox?: string;
    block?: boolean;
    // WeasyPrint doesn't currently support inheriting document styles into
    // inline SVGs, so we need to tell it what the current color is.
    printColor?: string;
};

const InlineIcon = (props: Props) => (
    <SVGRoot
        className={props.className}
        viewBox={props.viewBox}
        block={props.block || false}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            color={props.printColor ? props.printColor : undefined}
            stroke="currentColor"
            fill="currentColor"
        >
            {props.children}
        </g>
    </SVGRoot>
);

export default InlineIcon;
